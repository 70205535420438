import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import Nav from '@/components/Nav';
import Footer from '@/components/Footer';
import Banner from '@/components/PageBanner';
import ContactForm from '@/components/ContactForm';
import groq from 'groq';
import client from '../sanity_client';
import {toHTML} from '@portabletext/to-html';

export default function Book({pageContent, siteSettings, dataPoints}:any) {
  return (
    <>
      <Head>
        <title>The Line of Sight &#8480;</title>
      </Head>
      <Nav />
      <main className="row contents-row">
        <Banner
          bannerImage={`https://cdn.sanity.io/images/26sxkmoh/production/${pageContent[0].banner_image.asset._ref.replace('image-','').replace('-j','.j').replace('-p','.p')}`}
          mobileBannerHeading={pageContent[0].banner_heading}
        />
        <div className="col-12 contents-col">
          <div className="container">
            <div className="row title-row gy-5">
              <div className="col-12 title-col text-center title-font clear-both display-table">
                <h2>Order Now</h2>
              </div>
            </div>
            <div className="row title-row gx-5">
              <div className="col title-col text-center title-font">
                <Image
                  src={`https://cdn.sanity.io/images/26sxkmoh/production/${pageContent[0].book_image.asset._ref.replace('image-','').replace('-j','.j').replace('-p','.p')}`}
                  width={300}
                  height={500}
                  alt="An image of the Line of Sight book cover"
                  />
              </div>
              <div className="col title-col regular-font align-self-center">
                <h3>Available from these retailers</h3>
                <ul className="">
                  <li>
                    <Link href={`${pageContent[0].amazon_link}`} target='_blank'>Amazon</Link>
                  </li>
                  <li>
                    <Link href={`${pageContent[0].barnes_noble_link}`} target='_blank'>Barnes & Noble</Link>
                  </li>
                  <li>
                    <Link href={`${pageContent[0].simon_schuster_link}`} target='_blank'>Simon & Schuster</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row title-row">
              <div className="col-12 regular-font text-left" dangerouslySetInnerHTML={{__html: toHTML(pageContent[0].book_description)}}>
                {/* */}
              </div>
            </div>
            <div className="row title-row gx-5">
              <div className="col-12 title-col text-center title-font">
                <h2>About the Authors</h2>
              </div>

              <div className="row title-row gy-5">
                <div className="col-md-auto title-col title-font">
                  <Image
                    src={`https://cdn.sanity.io/images/26sxkmoh/production/${pageContent[0].author_image.asset._ref.replace('image-','').replace('-j','.j').replace('-p','.p')}`}
                    width={275}
                    height={250}
                    alt={`An image of the Line of Sight author ${pageContent[0].author_name}`}
                    className='float-left px-3'
                    />
                </div>
                <div className="col">
                  <h3>{pageContent[0].author_name}</h3>
                  <div className="regular-font" dangerouslySetInnerHTML={{__html: toHTML(pageContent[0].author_bio)}}>
                    {/* */}
                  </div>
                </div>
              </div>
              <div className="row title-row gy-5">
                <div className="col">
                  <h3>{pageContent[0].co_author_name}</h3>
                  <div className="regular-font text-left" dangerouslySetInnerHTML={{__html: toHTML(pageContent[0].co_author_bio)}}>
                    {/* */}
                  </div>
                </div>
                <div className="col-md-auto title-col text-left title-font">
                  <Image
                    src={`https://cdn.sanity.io/images/26sxkmoh/production/${pageContent[0].co_author_image.asset._ref.replace('image-','').replace('-j','.j').replace('-p','.p')}`}
                    width={350}
                    height={250}
                    alt={`An image of the Line of Sight author ${pageContent[0].co_author_name}`}
                    className='float-left px-5'
                    />
                </div>
              </div>
            </div>
            <div className="row title-row gx-5">
              <div className="col-12 title-col text-center title-font">
                <h2>{pageContent[0].story_title}</h2>
              </div>
              <div className="row title-row gy-5">
                <div className="col-12 text-left">
                  <div className="regular-font" dangerouslySetInnerHTML={{__html: toHTML(pageContent[0].story_text)}}>
                    {/* */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm adminEmails={siteSettings[0].admin_emails} />
      </main>
      <Footer />
    </>
  )
}

export async function getServerSideProps() {
  const pageContent = await client.fetch(`*[_type == "bookPage"] {
    title,
    banner_heading,
    banner_text,
    banner_image,
    book_image,
    book_description,
    book_details,
    author_name,
    author_bio,
    author_image,
    co_author_name,
    co_author_bio,
    co_author_image,
    amazon_link,
    barnes_noble_link,
    simon_schuster_link,
    story_title,
    story_text
  }`);
  const siteSettings = await client.fetch(groq`*[_type == "siteSettings"]`);
  const testamonials = await client.fetch(groq`*[_type == "bookPageTestamonials"] | order(sort_order asc)`);
  return {
    props: {
      pageContent: pageContent,
      siteSettings: siteSettings,
      dataPoints: testamonials
    }
  }
}
