import Link from "next/link";
import Image from 'next/image';
import React, { useEffect } from "react";

const PageBanner = ({bannerHeading, bannerSubHeading, bannerImage, mobileBannerHeading}:any) => {

  return (
    <>
      <div className="page-banner-col col-12 noPad">
        <div className="row banner-row pages-banner d-none d-sm-none d-md-none d-lg-block" style={{backgroundImage:`url(${bannerImage})`}} >
          <div className="col-12 banner-col">
            <div className="container">
              <div className="row inner-page-banner-row">
                {bannerSubHeading != '' ?
                  <div className="col-12">
                    <p className="banner-title-with-sub title-font">{bannerHeading}</p><br />
                    <p className="banner-title banner-sub-title title-font text-center">{bannerSubHeading}</p>
                  </div>
                  :
                  ''
                }
                {bannerHeading != '' && bannerSubHeading == '' ?
                  <div className="col-12">
                    <span className="banner-title title-font">{bannerHeading}</span><br />
                  </div>
                  :
                  ''
                }
                {/*{% endif %}*/}
              </div>
              <div className="container">
                {/*<img src="/img/white-sep-cropped.png" />*/}
              </div>
            </div>
          </div>
        </div>
        {/* mobile banner */}
        <div className="row banner-row pages-banner mobile-pages-banner-row d-block d-sm-block d-md-block d-lg-none">
          <div className="col-12 banner-col">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <span className="banner-title title-font">{bannerHeading||mobileBannerHeading}</span>

                  <p className="banner-title title-font vs-mobile-sub-heading">{bannerSubHeading}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageBanner;
