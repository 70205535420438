import Link from "next/link";
import Image from 'next/image';
import React, { useState, useEffect } from "react";
// @ts-ignore
import cookieCutter from 'cookie-cutter'
import { useForm } from "react-hook-form";

const ContactForm = ({adminEmails}:any) => {
  const { register, handleSubmit } = useForm();
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  async function submitEmailRegistration(data:any) {
    // @ts-ignore
    event?.preventDefault();

    let mailerData = {
      first_name: data.first_name,
      company: data.company,
      last_name: data.last_name,
      title: data.title,
      email: data.email,
      phone: data.phone,
      adminEmails: data.admin_emails,
    }

    const mailerOptions = {
      //
      method: 'POST',
      // Tell the server we're sending JSON.
      headers: {
        'Content-Type': 'application/json',
      },
      // Body of the request is the JSON data we created above.
      body: JSON.stringify(mailerData),
    }

    const contactEmail = await fetch(`/api/mailer/mailer`, mailerOptions);

    if(contactEmail.status == 200) {
      console.log("Email has been sent to new user, now setting cookie");
      // We redirect so no need to turn the saving state back.
      // @ts-ignore
      // window.location = `/app/${tenantId}/admin/users`;
      // setIsBeingSavedStatus(false);
      cookieCutter.set('losRegistrationStatus', '1') // 0 = not registered, 1 == registered
      //showThankYouMessage(true)
      //$('#contact_form')[0].reset();
      $('#contact_form').hide();
      $('#contact_form_message').show()
    } else {
      // ?
    }
  }

  return (
    <div className="col-12">
      <div className="row contact-form-row">
        <div className="col-12">
          <div className="container contact-form-container">
            <div className="row contact-form-row">
              <div className="col-12 contact-form-col-1">
                <h4 className="title-font">Your key to strategic execution awaits! 
Subscribe & receive targeted insights.</h4>
              </div>
              <div className="col-12 contact-form-col-1">
                <h4 id="contact_form_message" className="title-font hidden">Success!</h4>
              </div>
              <div className="form contact-form col-12">
                <form onSubmit={handleSubmit(submitEmailRegistration)} id="contact_form" className="row contact-form-fields">
                  <input
                    id="admin_emails"
                    {...register("admin_emails")}
                    type="hidden"
                    // @ts-ignore
                    value={adminEmails}
                  />
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="first_name"
                        {...register("first_name", { required: "First name is required"})}
                        type="text"
                        placeholder="First Name *"
                        autoComplete="first_name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="last_name"
                        {...register("last_name", { required: "Last name is required"})}
                        type="text"
                        placeholder="Last Name *"
                        autoComplete="last_name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="email"
                        {...register("email", { required: "A valid email is required", pattern: {value: /^[a-zA-Z0-9-+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "Must be a properly formatted email."} })}
                        type="text"
                        placeholder="Email *"
                        autoComplete="email"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="company"
                        {...register("company")}
                        type="text"
                        placeholder="Company"
                        autoComplete="company"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="title"
                        {...register("title")}
                        type="text"
                        placeholder="Title"
                        autoComplete="title"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 contact-form-col">
                    <div className="form-group">
                      <input
                        id="phone"
                        {...register("phone")}
                        type="text"
                        placeholder="Phone"
                        autoComplete="phone"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="group contact-button-container col-12">
                    <button id="registration-form-submit" type="submit" className="purple-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm;

